@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@500;700&display=swap");
#recipeapp {
  font-family: 'Readex Pro', sans-serif;
  background-color: #FFFEFC;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column; }
  #recipeapp #header {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../public/imgs/header.png");
    background-size: contain;
    flex-grow: 1; }
    @media (max-width: 1000px) {
      #recipeapp #header {
        background-size: cover; } }
  #recipeapp #icon {
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../public/imgs/icon.png");
    background-size: contain;
    height: 160px;
    width: 160px;
    margin: 48px auto; }
    @media (max-width: 1000px) {
      #recipeapp #icon {
        height: 128px;
        width: 128px;
        margin: 32px auto; } }
  #recipeapp #appstore {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../public/imgs/appstore.svg");
    background-size: contain;
    height: 56px;
    margin: 48px; }
    @media (max-width: 1000px) {
      #recipeapp #appstore {
        height: 40px;
        margin: 32px; } }
  #recipeapp h1 {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    padding: 0;
    margin: 0; }
    @media (max-width: 1000px) {
      #recipeapp h1 {
        font-size: 24px; } }
  #recipeapp h2 {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    padding: 0;
    margin: 0; }
    @media (max-width: 1000px) {
      #recipeapp h2 {
        font-size: 16px; } }
  #recipeapp #footer {
    list-style: none;
    font-weight: 500;
    font-size: 12px;
    color: #aaa;
    margin: 48px;
    display: block;
    padding: 0; }
    #recipeapp #footer li {
      display: inline;
      padding: 16px; }
      @media (max-width: 1000px) {
        #recipeapp #footer li {
          display: block;
          padding: 0px; } }
    #recipeapp #footer a {
      color: #aaa;
      text-decoration: none; }
      #recipeapp #footer a:hover {
        text-decoration: underline; }
    @media (max-width: 1000px) {
      #recipeapp #footer {
        margin: 32px; } }
